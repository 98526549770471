.copyright-text {
  margin-top: 4rem;
  font-family: "Poppins", sans-serif;
  padding: 0.4rem 0 4rem 0.4rem;
  animation: revealText 0.4s forwards;
}

@media (min-width: 481px) and (max-width: 767px) {
  .copyright-text {
    margin-top: 2rem;
    font-size: small;
    padding: 0.4rem 0 2rem 0.4rem;
  }
}

@media (min-width: 351px) and (max-width: 480px) {
  .copyright-text {
    margin-top: 2rem;
    font-size: x-small;
    padding: 0.4rem 0 2rem 0.4rem;
  }
}

@media (min-width: 200px) and (max-width: 350px) {
  .copyright-text {
    margin-top: 1.2rem;
    font-size: xx-small;
    padding: 0.4rem 1.2rem 2rem 1.2rem;
  }
}

@keyframes revealText {
  from {
    transform: translateY(20px);
  }
  to {
    transform: none;
  }
}
