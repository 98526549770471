.section {
  display: flex;
  font-family: "Poppins", sans-serif;
  justify-content: space-between;
  overflow: visible;
  max-width: 100%;
  margin-bottom: 0;
  margin: 0 3rem 0 3rem;
}

.container {
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.about {
  display: grid;
  margin-top: 120px;
  margin-bottom: 140px;
  align-items: center;
  grid-column-gap: 50px;
  grid-row-gap: 30px;
  grid-template-columns: 1.25fr 2fr;
  grid-template-rows: auto;
  animation: revealText 0.4s forwards;
}

.image-wrap {
  display: flex;
}

.image {
  width: 20rem;
  border-radius: 4rem;
}

.about-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 26rem;
}

.heading {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 80px;
  line-height: 90px;
  font-weight: 500;
}

.paragraph-light {
  display: flex;
  text-align: start;
  padding-bottom: 0;
  font-weight: 400;
  letter-spacing: 0;
  font-size: 25px;
  line-height: 40px;
}

span {
  display: contents;
}

.work-link {
  display: contents;
  color: unset;
  text-decoration: none;
}

.work-link:hover {
  color: #5c83ad;
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image {
    width: 18rem;
  }

  .about {
    display: grid;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .about-head {
    height: 23rem;
  }

  .heading {
    font-size: 70px;
    line-height: 70px;
  }

  .paragraph-light {
    font-size: 22px;
    line-height: 34px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image {
    width: 16rem;
  }

  .about {
    display: grid;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .about-head {
    height: 20.5rem;
  }

  .heading {
    font-size: 48px;
    line-height: 65px;
  }

  .paragraph-light {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-wrap {
    width: 100%;
    height: 18rem;
  }

  .image {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 14rem;
    height: 100%;
  }

  .about {
    display: flex;
    flex-direction: column;
    margin: 4rem 0 4rem 0;
  }

  .about-head {
    height: 100%;
  }

  .heading {
    font-size: 50px;
    line-height: 65px;
  }

  .paragraph-light {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (min-width: 351px) and (max-width: 480px) {
  .section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-wrap {
    width: 100%;
    height: 15rem;
  }

  .image {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 12rem;
    height: 100%;
  }

  .about {
    display: flex;
    flex-direction: column;
    margin: 4rem 0 4rem 0;
  }

  .about-head {
    height: 100%;
  }

  .heading {
    font-size: 45px;
    line-height: 65px;
  }

  .paragraph-light {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

@media (min-width: 200px) and (max-width: 350px) {
  .section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 0 1.2rem 0 1.2rem;
  }

  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about {
    display: flex;
    flex-direction: column;
    margin: 2rem 0 2rem 0;
  }

  .about-head {
    height: 100%;
  }

  .heading {
    font-size: 40px;
    line-height: 60px;
  }

  .paragraph-light {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .image-wrap {
    width: 100%;
    height: 12.5rem;
  }

  .image {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 10rem;
    height: 100%;
  }
}

@keyframes revealText {
  from {
    transform: translateY(20px);
  }
  to {
    transform: none;
  }
}
