.header-wrap {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  height: 100%;
  padding: 1.2rem 3rem;
  align-items: center;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
}

.wrap {
  display: flex;
}

.name {
  padding: 0 1rem 0.2rem 0;
  font-weight: 600;
  font-size: 18px;
}

.dark {
  padding: 0rem 2rem;
}

@media (min-width: 481px) and (max-width: 767px) {
  .dark {
    padding: 0rem 2rem;
  }
}

@media (min-width: 351px) and (max-width: 480px) {
  .dark {
    padding: 0rem 1.8rem;
  }
}

@media (min-width: 200px) and (max-width: 350px) {
  .header {
    padding: 0.8rem 1.2rem;
  }
}
