.App {
  text-align: center;
  background-color: #ebf0f5;
  color: #1b2836;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

body {
  font-family: "Inter", sans-serif;
  background-color: #ebf0f5;
  color: #1b2836;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.dark-mode {
  background-color: #1b2836;
  color: #d6e0ea;
}

.empty {
  background-color: #1b2836;
  width: 100%;
  height: 1.4rem;
}

.nav {
  padding-right: 0rem;
  position: absolute;
  margin: 1.64rem 3rem 0.8rem 1rem;
  right: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 28px;
}

.switch:hover {
  transform: scale(1.02);
  transition: transform 1s, filter 0.5s ease-out;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1b2836;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: #ebf0f5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ebf0f5;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
  background-color: #1b2836;
}

.slider.round {
  border-radius: 34px;
  display: flex;
}

.slider.round:before {
  border-radius: 50%;
}

.icon-sun {
  display: flex;
  justify-content: flex-start;
  color: #7faec2;
  width: 80%;
  padding: 6px 0px 0px 8px;
}

.icon-moon {
  display: flex;
  justify-content: flex-end;
  color: #7faec2;
  width: 80%;
  padding: 6px 8px 0px 0px;
}

@media (min-width: 351px) and (max-width: 480px) {
  .nav {
    margin: 1.2rem 3rem 1.4rem 1rem;
  }
}
@media (min-width: 200px) and (max-width: 350px) {
  .nav {
    margin: 0.8rem 1.2rem 0.8rem 0.8rem;
  }
}
